import { ref, Ref, computed, ComputedRef } from 'vue'
import { IReleaseInfoParams } from '@/service/proRequisition/type'
import { getReleaseInfo } from '@/service/proRequisition'

export default function userList(
  params: Ref<IReleaseInfoParams>,
  tabActive: Ref<number>
): [
  Ref<{
    [x: string]: any
  }>,
  ComputedRef<{ [propName: string]: string }[]>,
  Ref<boolean>,
  () => Promise<void>
] {
  const finished = ref(false)
  // const list = ref<{ [propName: string]: string }[]>([])
  const detail = ref<{ [propName: string]: any }>({})

  const getReleaseInfoAsync = async () => {
    const res = await getReleaseInfo(params.value)
    detail.value = res.data
    finished.value = true
  }
  const list = computed(() => {
    console.log('list', tabActive.value)
    let arr: { [propName: string]: string }[] = []
    switch (tabActive.value) {
      case 0:
        arr = detail.value.slaves
        break
      case 1:
        arr = detail.value.done_slave
        break
      case 2:
        arr = detail.value.doing_slave
        break
    }
    return arr
  })

  return [detail, list, finished, getReleaseInfoAsync]
}
