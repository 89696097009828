import { ref, Ref } from 'vue'
import { getUserInfo, getDepartmentInfo } from '@/service/proRequisition'

export default function useUserInfo(): [
  Ref<{
    u_name: string
  }>,
  () => Promise<void>,
  Ref<{ [x: string]: any }>,
  () => Promise<any>
] {
  const userInfo = ref({
    u_name: ''
  })
  //获取当前用户的部门详情信息
  const userDeptInfo = ref<{ [propName: string]: any }>({})

  const getUserInfoAsync = async () => {
    const res = await getUserInfo()
    userInfo.value.u_name = res.data.user_name
  }
  //获取用户的详细部门信息
  const getDepartmentInfoAsync = async () => {
    const res = await getDepartmentInfo({
      id: 0,
      user_name: userInfo.value.u_name
    })
    userDeptInfo.value = res.data.length ? res.data[0] : {}
  }

  return [userInfo, getUserInfoAsync, userDeptInfo, getDepartmentInfoAsync]
}
