import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5feb09c1"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "materialPositionInfo"
};
var _hoisted_2 = {
  class: "footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_cell = _resolveComponent("van-cell");
  var _component_van_button = _resolveComponent("van-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_cell, {
    title: "物料名称："
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.info.stone_name), 1)];
    }),
    _: 1
  }), _createVNode(_component_van_cell, {
    title: "规格型号："
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.info.stone_specifications), 1)];
    }),
    _: 1
  }), _createVNode(_component_van_cell, {
    title: "当前货位："
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.info.position), 1)];
    }),
    _: 1
  }), _createVNode(_component_van_cell, {
    title: "申请数量："
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.release_info.release_nums) + _toDisplayString(_ctx.release_info.release_unit), 1)];
    }),
    _: 1
  }), _createVNode(_component_van_cell, {
    title: "领用数量："
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.release_info.release_nums) + _toDisplayString(_ctx.release_info.release_unit), 1)];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
    block: "",
    type: "primary",
    onClick: _ctx.toPickingExWarehouse
  }, {
    default: _withCtx(function () {
      return [_createTextVNode("确定")];
    }),
    _: 1
  }, 8, ["onClick"])])]);
}