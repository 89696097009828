import { ref, Ref } from 'vue'
import { IConfirmReleaseParams } from '@/service/proRequisition/type'
import { confirmRelease } from '@/service/proRequisition'
import { IRequestResult } from '@/service/types'
export default function userList(
  params: Ref<IConfirmReleaseParams>
): [() => Promise<IRequestResult<any>>] {
  const confirmReleaseAsync = async () => {
    const res = await confirmRelease(params.value)
    return res
  }

  return [confirmReleaseAsync]
}
