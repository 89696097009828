import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4f0794c6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "SelectWarehouse"
};
var _hoisted_2 = {
  class: "header"
};
var _hoisted_3 = {
  class: "search-box bg-white"
};
var _hoisted_4 = {
  class: "title flex align-center"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = ["onClick"];
var _hoisted_7 = ["onClick"];
var _hoisted_8 = {
  class: "list"
};
var _hoisted_9 = {
  class: "footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_search = _resolveComponent("van-search");
  var _component_van_icon = _resolveComponent("van-icon");
  var _component_van_radio = _resolveComponent("van-radio");
  var _component_van_cell = _resolveComponent("van-cell");
  var _component_van_radio_group = _resolveComponent("van-radio-group");
  var _component_c_no_data = _resolveComponent("c-no-data");
  var _component_van_button = _resolveComponent("van-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_search, {
    modelValue: _ctx.searchVal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.searchVal = $event;
    }),
    placeholder: "请输入关键字搜索",
    shape: "round",
    background: "#ffffff",
    onSearch: _ctx.confirmSearch
  }, null, 8, ["modelValue", "onSearch"])]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tree_stack, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "inline-item",
      key: index
    }, [index == 0 ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "inline-item",
      onClick: function onClick($event) {
        return _ctx.backTree(item, -1);
      }
    }, [_createElementVNode("text", {
      class: _normalizeClass([index == _ctx.tree_stack.length - 1 && !_ctx.isre ? 'none' : 'active'])
    }, "全部", 2)], 8, _hoisted_5)) : _createCommentVNode("", true), index == 0 && _ctx.isre ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      onClick: function onClick($event) {
        return _ctx.backTree(item, -2);
      },
      class: _normalizeClass([index == _ctx.tree_stack.length - 1 && _ctx.isre] ? 'none inline-item' : 'active inline-item')
    }, [_createVNode(_component_van_icon, {
      name: "arrow",
      color: "#999999",
      size: "20px"
    }), _createTextVNode(" 搜索结果 ")], 10, _hoisted_6)) : _createCommentVNode("", true), index != 0 ? (_openBlock(), _createElementBlock("div", {
      key: 2,
      class: "inline-item",
      onClick: function onClick($event) {
        return _ctx.backTree(item, index);
      }
    }, [_createVNode(_component_van_icon, {
      name: "arrow",
      color: "#999999",
      size: "20px"
    }), _createElementVNode("text", {
      class: _normalizeClass(index == _ctx.tree_stack.length - 1 ? 'none inline-ite' : 'active')
    }, _toDisplayString(item.name), 3)], 8, _hoisted_7)) : _createCommentVNode("", true)]);
  }), 128))])]), _createElementVNode("view", _hoisted_8, [_ctx.list.length ? (_openBlock(), _createBlock(_component_van_radio_group, {
    key: 0,
    modelValue: _ctx.checked.id,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.checked.id = $event;
    })
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, function (item, index) {
        return _openBlock(), _createBlock(_component_van_cell, {
          key: index,
          title: item.name,
          "is-link": !item.user,
          clickable: "",
          onClick: function onClick($event) {
            return _ctx.getChildren(item);
          }
        }, {
          icon: _withCtx(function () {
            return [_createVNode(_component_van_radio, {
              name: item.id
            }, null, 8, ["name"])];
          }),
          _: 2
        }, 1032, ["title", "is-link", "onClick"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["modelValue"])) : !_ctx.firstLoad ? (_openBlock(), _createBlock(_component_c_no_data, {
    key: 1
  })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_van_button, {
    type: "primary",
    round: "",
    block: "",
    onClick: _ctx.confirm
  }, {
    default: _withCtx(function () {
      return [_createTextVNode("确定")];
    }),
    _: 1
  }, 8, ["onClick"])])]);
}