import { ref, Ref } from 'vue'
import { IExecReleaseParams } from '@/service/proRequisition/type'
import { execRelease } from '@/service/proRequisition'
import { IRequestResult } from '@/service/types'
export default function userList(
  params: Ref<IExecReleaseParams>
): [() => Promise<IRequestResult<any>>] {
  const execReleaseAsync = async () => {
    const res = await execRelease(params.value)
    return res
  }

  return [execReleaseAsync]
}
