import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'RetrievalTypePopup',
  components: {},
  emits: ['confirm-type'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var show = ref(false);
    //出库类型
    var type = ref([{
      text: '直接领用',
      value: 2
    }
    // { text: '临时借用', value: 4 }
    ]);
    var openPopup = function openPopup() {
      show.value = true;
    };
    var onConfirm = function onConfirm(e) {
      show.value = false;
      emit('confirm-type', e);
    };
    var onCancel = function onCancel() {
      show.value = false;
    };
    return {
      show: show,
      type: type,
      openPopup: openPopup,
      onConfirm: onConfirm,
      onCancel: onCancel
    };
  }
});