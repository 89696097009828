import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-26521f9c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "SelectWarehouse"
};
var _hoisted_2 = {
  class: "search-box"
};
var _hoisted_3 = {
  class: "pt-2 list"
};
var _hoisted_4 = {
  class: "title"
};
var _hoisted_5 = {
  class: "info"
};
var _hoisted_6 = {
  class: "mr-4"
};
var _hoisted_7 = {
  key: 1,
  class: "box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_search = _resolveComponent("van-search");
  var _component_van_cell = _resolveComponent("van-cell");
  var _component_van_cell_group = _resolveComponent("van-cell-group");
  var _component_van_list = _resolveComponent("van-list");
  var _component_c_no_data = _resolveComponent("c-no-data");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_search, {
    modelValue: _ctx.params.warehouse_name,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return _ctx.params.warehouse_name = $event;
    }), _ctx.onSearch],
    placeholder: "搜索",
    shape: "round",
    background: "#ffffff"
  }, null, 8, ["modelValue", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_3, [_ctx.list.length ? (_openBlock(), _createBlock(_component_van_list, {
    key: 0,
    loading: _ctx.loading,
    "onUpdate:loading": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.loading = $event;
    }),
    finished: _ctx.finished,
    "finished-text": "没有更多了",
    onLoad: _ctx.onLoad
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_cell_group, {
        inset: ""
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, function (item) {
            return _openBlock(), _createBlock(_component_van_cell, {
              clickable: "",
              key: item,
              onClick: function onClick($event) {
                return _ctx.toggle(item);
              }
            }, {
              title: _withCtx(function () {
                return [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_4, _toDisplayString(item.warehouse_name), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, "仓库位置：" + _toDisplayString(item.warehouse_address), 1)])])];
              }),
              _: 2
            }, 1032, ["onClick"]);
          }), 128))];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["loading", "finished", "onLoad"])) : _createCommentVNode("", true), !_ctx.list.length && _ctx.finished ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_c_no_data)])) : _createCommentVNode("", true)])]);
}