import { ref, Ref } from 'vue'
import { IWarehouseItem } from '@/service/proRequisition/type'
import { getWarehouseList } from '@/service/proRequisition'

export default function useDefaultWarehouse(): [
  Ref<IWarehouseItem | undefined>,
  () => Promise<void>
] {
  const defaultWarehouse = ref<IWarehouseItem>()

  const getDefaultWarehouseAsync = async () => {
    const res = await getWarehouseList({
      limit: 10,
      page: 1,
      warehouse_name: '备品备件'
    })
    if (res.data.data.length) {
      defaultWarehouse.value = res.data.data[0]
    }
  }

  return [defaultWarehouse, getDefaultWarehouseAsync]
}
