import { hyRequest4 } from '@/service'
import { ComputedRef } from 'vue'
import {
  IConsumptionInfo,
  IEasybuyRes,
  ILogInfo,
  ILogParams,
  IRechargeInfo,
  ITimeRange
} from './type'

const factory_id = 358 // 蓝天厂区id
// 数据总览
export function getAllData(params: ITimeRange): Promise<any> {
  return hyRequest4.post({
    url: '/ygBoss/statistics/dataByDate',
    data: {
      factory_id,
      ...params
    }
  })
}

// 充值统计
export function rechargeCount(
  params: ITimeRange
): Promise<IEasybuyRes<IRechargeInfo>> {
  return hyRequest4.post({
    url: '/ygBoss/statistics/chzByDate',
    data: {
      factory_id,
      ...params
    }
  })
}

// 充值方式列表
export function rechargeTypeList(params: ITimeRange): Promise<any> {
  return hyRequest4.post({
    url: '/ygBoss/statistics/chzByDateTwo',
    data: {
      factory_id,
      ...params
    }
  })
}

// 消费统计
export function consumptionCount(
  params: ITimeRange
): Promise<IEasybuyRes<IConsumptionInfo>> {
  return hyRequest4.post({
    url: '/ygBoss/statistics/saleByDate',
    data: {
      factory_id,
      ...params
    }
  })
}

// 充值记录
export function rechargeLog(
  data: ILogParams
): Promise<IEasybuyRes<ILogInfo[]>> {
  return hyRequest4.post({
    url: '/ygBoss/statistics/chzSortByDate',
    data: {
      ...data,
      factory_id
    }
  })
}

// 消费记录
export function consumptionLog(
  data: ILogParams
): Promise<IEasybuyRes<ILogInfo[]>> {
  return hyRequest4.post({
    url: 'ygBoss/statistics/saleSortByDate',
    data: {
      ...data,
      factory_id
    }
  })
}

// 新增客户数据
export function customerCount(params: ITimeRange): Promise<any> {
  return hyRequest4.post({
    url: '/ygBoss/statistics/cusByDate',
    data: {
      factory_id,
      ...params
    }
  })
}
