import { ref, Ref } from 'vue'
import { getOAToken } from '@/service/proRequisition'

export default function userList(
  params: Ref<{
    loginid: string
  }>
): [Ref<string>, () => Promise<void>] {
  const oaToken = ref('')

  const getOATokenAsync = async () => {
    const res = await getOAToken(params.value)
    oaToken.value = res.data
  }

  return [oaToken, getOATokenAsync]
}
