import hyRequest, { hyRequest1 } from '@/service'
import { IRequestResult } from '@/service/types'
import {
  IDictsItem,
  IListData,
  IWarehouseListParams,
  IMaterialListParams,
  IWarehouseItem,
  IApplyParams,
  IReleaseListParams,
  IReleaseInfoParams,
  IArchivesInfoParams,
  IExecReleaseParams,
  IConfirmReleaseParams,
  ISignReleaseSlaveParams
} from './type'

enum DashboardApi {
  getDicts = '/terminal/terminalInfo/getDicts',
  getUserList = '/manager/warehouse/userList',
  getWarehouseList = '/manager/warehouse/warehouseList',
  getStoneList = '/manager/warehouse/stoneList',
  releaseApply = '/manager/warehouse/releaseApply',
  getReleaseList = '/manager/warehouse/releaseList',
  getUserInfo = '/appUser/getUserInfo',
  getReleaseInfo = '/smart/warehouse/releaseInfoManager',
  getArchivesInfo = '/smart/warehouse/archivesInfo',
  execRelease = '/smart/warehouse/execRelease',
  confirmRelease = '/smart/warehouse/confirmReleaseNew',
  signReleaseSlave = '/smart/warehouse/signReleaseSlave',
  getOAToken = '/oa/getOAToken',
  getDepartmentList = 'smart/warehouse/departmentList',
  getDepartmentInfo = 'smart/warehouse/departmentInfo'
}

//领用物品类型
export function getDicts(): Promise<IRequestResult<IDictsItem[]>> {
  return hyRequest1.get<IRequestResult<IDictsItem[]>>({
    url: `${DashboardApi.getDicts}?name=oa_type`
  })
}

//领用员工
export function getUserList(params: {
  page: number
  limit: number
  username?: string
}): Promise<IRequestResult<any>> {
  return hyRequest1.get<IRequestResult<any>>({
    url: `${DashboardApi.getUserList}`,
    params
  })
}
//选择仓库
export function getWarehouseList(
  params: IWarehouseListParams
): Promise<IRequestResult<IListData<IWarehouseItem>>> {
  return hyRequest1.get<IRequestResult<IListData<IWarehouseItem>>>({
    url: `${DashboardApi.getWarehouseList}`,
    params
  })
}

//选择物料
export function getStoneList(
  params: IMaterialListParams
): Promise<IRequestResult<IListData>> {
  return hyRequest1.get<IRequestResult<IListData>>({
    url: `${DashboardApi.getStoneList}`,
    params
  })
}

//领用提交
export function releaseApply(params: IApplyParams): Promise<IRequestResult> {
  return hyRequest1.post<IRequestResult>({
    url: `${DashboardApi.releaseApply}`,
    data: params
  })
}

//出库单列表
export function getReleaseList(
  params: IReleaseListParams
): Promise<IRequestResult<IListData>> {
  return hyRequest1.get<IRequestResult<IListData>>({
    url: `${DashboardApi.getReleaseList}`,
    params
  })
}

//获取当前用户信息
export function getUserInfo(): Promise<IRequestResult<any>> {
  return hyRequest.get<IRequestResult<any>>({
    url: `${DashboardApi.getUserInfo}`
  })
}

//获取出库单详情
export function getReleaseInfo(
  params: IReleaseInfoParams
): Promise<IRequestResult<any>> {
  return hyRequest1.get<IRequestResult<any>>({
    url: `${DashboardApi.getReleaseInfo}`,
    params
  })
}

//获取领用物料的详情
export function getArchivesInfo(
  params: IArchivesInfoParams
): Promise<IRequestResult<any>> {
  return hyRequest1.get<IRequestResult<any>>({
    url: `${DashboardApi.getArchivesInfo}`,
    params
  })
}
//确定领用物料
export function execRelease(
  params: IExecReleaseParams
): Promise<IRequestResult> {
  return hyRequest1.post<IRequestResult>({
    url: `${DashboardApi.execRelease}`,
    data: params
  })
}

//标记领用物料已领未领
export function signReleaseSlave(
  params: ISignReleaseSlaveParams
): Promise<IRequestResult> {
  return hyRequest1.post<IRequestResult>({
    url: `${DashboardApi.signReleaseSlave}`,
    data: params
  })
}
//确定出库
export function confirmRelease(
  params: IConfirmReleaseParams
): Promise<IRequestResult> {
  return hyRequest1.post<IRequestResult>({
    url: `${DashboardApi.confirmRelease}`,
    data: params
  })
}

//获取oatoken
export function getOAToken(data: { loginid: string }): Promise<IRequestResult> {
  return hyRequest.post<IRequestResult>({
    url: `${DashboardApi.getOAToken}`,
    data
  })
}

//第一层列表
export function getDepartmentList(): Promise<IRequestResult> {
  return hyRequest1.get<IRequestResult>({
    url: `${DashboardApi.getDepartmentList}`
  })
}

//部门信息 参数id 部门id
export function getDepartmentInfo(params: {
  id: number
  user_name: string
}): Promise<IRequestResult> {
  return hyRequest1.get<IRequestResult>({
    url: `${DashboardApi.getDepartmentInfo}`,
    params
  })
}
