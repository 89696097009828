import { ref, Ref } from 'vue'
import { IReleaseListParams } from '@/service/proRequisition/type'
import { getReleaseList } from '@/service/proRequisition'

export default function userList(params: Ref<IReleaseListParams>): [
  Ref<
    {
      [x: string]: string
    }[]
  >,
  Ref<boolean>,
  Ref<boolean>,
  () => Promise<void>,
  () => void
] {
  const loading = ref(false)
  const finished = ref(false)
  const list = ref<{ [propName: string]: string }[]>([])

  const getReleaseListAsync = async () => {
    try {
      loading.value = true

      const res = await getReleaseList(params.value)
      if (params.value.page === 1) {
        list.value = res.data.data
      } else {
        list.value.push(...res.data.data)
      }

      // 数据全部加载完成
      if (res.data.total === list.value.length) {
        finished.value = true
      }

      // 加载状态结束
      loading.value = false
    } catch (error) {
      params.value.page -= 1
    }
  }
  const onLoad = () => {
    params.value.page += 1
    getReleaseListAsync()
  }
  return [list, loading, finished, getReleaseListAsync, onLoad]
}
