import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
enum DashboardApi {
  addFinanceBudget = '/info/addBudgetAmt',
  getDeptList = '/info/qryUserOrg',
}

export function addFinanceBudget(data: {
  budgetAmt: string,
  remark: string
}): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: `${DashboardApi.addFinanceBudget}`,
    data
  })
}

export function getDeptList(): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: `${DashboardApi.getDeptList}`
  })
}