import { ref, Ref } from 'vue'
import { IMaterialListParams } from '@/service/proRequisition/type'
import { getStoneList } from '@/service/proRequisition'

export default function userList(
  params: Ref<IMaterialListParams>
): [Ref<any[]>, Ref<boolean>, Ref<boolean>, () => Promise<void>, () => void] {
  const loading = ref(false)
  const finished = ref(false)
  const list = ref<any[]>([])

  const getStoneListAsync = async () => {
    try {
      loading.value = true
      const res = await getStoneList(params.value)
      list.value.push(...res.data.data)

      // 数据全部加载完成
      if (res.data.total === list.value.length) {
        finished.value = true
      }

      // 加载状态结束
      loading.value = false
    } catch (error) {
      params.value.page -= 1
    }
  }
  const onLoad = () => {
    params.value.page += 1
    getStoneListAsync()
  }
  return [list, loading, finished, getStoneListAsync, onLoad]
}
