import { ref, Ref } from 'vue'
import { ITree } from '@/service/proRequisition/type'
import { getDepartmentList, getDepartmentInfo } from '@/service/proRequisition'
import { Toast } from 'vant'
export default function userList(): [
  Ref<boolean>,
  Ref<
    {
      [x: string]: any
    }[]
  >,
  () => Promise<void>,
  (params: { id: number; user_name: string }) => Promise<void>
] {
  const firstLoad = ref(true)
  const list = ref<ITree[]>([])

  const getDepartmentListAsync = async () => {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    const res = await getDepartmentList()
    console.log('departmentlist', res.data)
    list.value.push(
      ...res.data.map((item: any) => {
        return {
          ...item,
          name: item.dept_name,
          user: false
        }
      })
    )
    firstLoad.value = false
    setTimeout(() => {
      Toast.clear()
    })
  }
  const getDepartmentInfoAsync = async (params: {
    id: number
    user_name: string
  }) => {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    const res = await getDepartmentInfo(params)
    console.log('departmentinfo', res.data)

    list.value.push(
      ...res.data.map((item: any) => {
        return {
          ...item,
          name: item.data_type === 1 ? item.name : item.dept_name,
          user: item.data_type === 1 ? true : false // data_type  1员工 2部门
        }
      })
    )
    firstLoad.value = false
    setTimeout(() => {
      Toast.clear()
    })
  }

  return [firstLoad, list, getDepartmentListAsync, getDepartmentInfoAsync]
}
