import { ref, Ref } from 'vue'
import { IArchivesInfoParams } from '@/service/proRequisition/type'
import { getArchivesInfo } from '@/service/proRequisition'

export default function userList(params: Ref<IArchivesInfoParams>): [
  Ref<{
    [x: string]: any
  }>,
  () => Promise<void>
] {
  const info = ref<{ [propName: string]: any }>({})

  const getArchivesInfoAsync = async () => {
    const res = await getArchivesInfo(params.value)
    info.value = res.data
  }

  return [info, getArchivesInfoAsync]
}
