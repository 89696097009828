import { ref, Ref } from 'vue'
import { ISignReleaseSlaveParams } from '@/service/proRequisition/type'
import { signReleaseSlave } from '@/service/proRequisition'
import { IRequestResult } from '@/service/types'
export default function userList(
  params: Ref<ISignReleaseSlaveParams>
): [() => Promise<IRequestResult<any>>] {
  const signReleaseSlaveAsync = async () => {
    const res = await signReleaseSlave(params.value)
    return res
  }

  return [signReleaseSlaveAsync]
}
