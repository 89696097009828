import {
  ITimeRange,
  ICountInfo,
  ICustomerInfo,
  ILogInfo,
  IRechargeInfo,
  IConsumptionInfo
} from '@/service/sale/count/type'
import {
  consumptionCount,
  consumptionLog,
  customerCount,
  getAllData,
  rechargeCount,
  rechargeLog,
  rechargeTypeList
} from '@/service/sale/count'
import { computed, ComputedRef, Ref, ref } from 'vue'
import dayjs from 'dayjs'

// 统计信息
export default function countInfo(
  params: { date: string; type: number },
  logType: Ref<string>
) {
  const page = {
    page: 1,
    list_rows: 10
  }

  const listFinished = ref(false)
  const listLoading = ref(false)

  // 时间区间
  const timeRange: ComputedRef<ITimeRange> = computed(() => {
    if (params.type === 0) {
      return {
        startTime: dayjs(params.date)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs(params.date).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
    } else if (params.type === 1) {
      return {
        startTime: dayjs(params.date)
          .startOf('month')
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs(params.date).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      }
    } else if (params.type === 2) {
      return {
        startTime: dayjs(params.date)
          .startOf('year')
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs(params.date).endOf('year').format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      return {
        startTime: '',
        endTime: ''
      }
    }
  })

  // 统计信息
  const countInfo = ref<ICountInfo>({
    orderCount: '',
    carCount: ''
  })
  const getCountInfo = async () => {
    const res = await getAllData(timeRange.value)
    countInfo.value = res.data
  }

  // 新增客户
  const customerCountInfo = ref<ICustomerInfo>({
    userCount: ''
  })
  const getCustomerCountInfo = async () => {
    const res = await customerCount(timeRange.value)
    customerCountInfo.value = res.data
  }

  // 充值记录
  const logInfo = ref<ILogInfo[]>([])
  const getLogInfo = async () => {
    listLoading.value = true
    let res
    if (logType.value === '充值(元)') {
      res = await rechargeLog(Object.assign({}, page, timeRange.value))
    } else {
      res = await consumptionLog(Object.assign({}, page, timeRange.value))
    }

    logInfo.value.push(...res.data)
    listLoading.value = false
    page.page++

    if (res.data.length < page.list_rows) {
      listFinished.value = true
    }
  }

  // 充值方式
  const rechargeTypeInfo = ref([])
  const getRechargeTypeInfo = async () => {
    const res = await rechargeTypeList(timeRange.value)
    rechargeTypeInfo.value = res.data
  }

  // 充值统计
  const rechargeCountInfo = ref<IRechargeInfo>({
    upDownMoney: '',
    upOnMoney: ''
  })
  const getRechargeCountInfo = async () => {
    const res = await rechargeCount(timeRange.value)
    rechargeCountInfo.value = res.data
  }

  // 消费统计
  const consumptionCountInfo = ref<IConsumptionInfo>({
    saleUpMoney: '',
    saleDownMoney: ''
  })
  const getConsumptionCountInfo = async () => {
    const res = await consumptionCount(timeRange.value)
    consumptionCountInfo.value = res.data
  }

  //重置分页
  const resetPage = () => {
    page.page = 1
    logInfo.value = []
    listFinished.value = false
    listLoading.value = false
  }

  return {
    resetPage,
    listFinished,
    listLoading,
    countInfo,
    getCountInfo,

    customerCountInfo,
    getCustomerCountInfo,

    logInfo,
    getLogInfo,

    rechargeTypeInfo,
    getRechargeTypeInfo,

    rechargeCountInfo,
    getRechargeCountInfo,

    consumptionCountInfo,
    getConsumptionCountInfo
  }
}
