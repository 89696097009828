import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-84a2872c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "materialItem"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "info"
};
var _hoisted_4 = {
  class: "ml-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.stone_name) + _toDisplayString(_ctx.item.stone_specifications ? "/".concat(_ctx.item.stone_specifications) : ''), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, "库存数量：" + _toDisplayString(_ctx.item.stone_stock), 1), _createElementVNode("span", _hoisted_4, "单位：" + _toDisplayString(_ctx.item.unit), 1)])]);
}